import React, { useEffect, useState } from 'react';
import './Community.scss';
import MainURL from '../../MainURL';
import axios from 'axios';
import kakaologo from "../../images/login/kakao.png"
import instarlogo from "../../images/instarlogo.jpeg"
import { useLocation, useNavigate } from 'react-router-dom';
import DateFormmating from '../../components/DateFormmating';
import { useRecoilState, useRecoilValue } from 'recoil';
import { recoilLoginState, recoilUserData } from '../../RecoilStore';

export default function ListGradeRequest (props:any) {

  let navigate = useNavigate();

  const isLogin = useRecoilValue(recoilLoginState);
  const [userData, setUserData] = useRecoilState(recoilUserData);

  interface ListProps {
    id : number;
    sort : string;
    title : string;
    content : string;
    userAccount : string;
    userNickName : string;
    isLiked : string;
    date : string;
    views : string;
    images : [string]
  }
  
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [list, setList] = useState<ListProps[]>([]);
  const [listAllLength, setListAllLength] = useState<number>(0);
  const fetchDatas = async () => {
    const res = await axios.get(`${MainURL}/board/getposts/${props.sort}/${currentPage}`);
    if (res.data) {
      const copy = res.data.resultData;
      setList(copy);
      setListAllLength(res.data.totalCount);
    }
  }

  useEffect(()=>{
    fetchDatas();
  }, [currentPage]);

  // State 변수 추가
  const itemsPerPage = 10; // 한 페이지당 표시될 게시글 수
  const totalPages = Math.ceil(listAllLength / itemsPerPage);

  // 페이지 변경 함수
  const changePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // 글자수 제한
  const renderPreview = (content : string) => {
    if (content?.length > 40) {
      return content.substring(0, 40) + '...';
    }
    return content;
  };

    
  // 페이지네이션 범위 계산
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 4;
    const half = Math.floor(maxPagesToShow / 2);
    let start = Math.max(1, currentPage - half);
    let end = Math.min(totalPages, currentPage + half);

    if (currentPage - half < 1) {
      end = Math.min(totalPages, end + (half - currentPage + 1));
    }

    if (currentPage + half > totalPages) {
      start = Math.max(1, start - (currentPage + half - totalPages));
    }

    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };


  // 글쓰기 함수
  const openPostPage = async () => {

    if (!isLogin) {
      alert('권한이 없습니다. 로그인이 필요합니다.')
    } else {
      axios.post(`${MainURL}/board/checkisposting`, {
        userAccount : userData.userAccount
      })
      .then((res)=>{
        if (res.data) {
          navigate('/community/post', {state : {sort:props.sort, menuNum:props.num}});  
        } else {
          if (props.sort === 'graderequest') {
            alert('먼저 게시글에 1개 이상의 댓글을 작성후에 등업신청을 하셔야, 등업이 완료됩니다.')
          }
        }
      }).catch((error)=>{
        console.error(error);
      })
    }
  };

  return (
    <div className="subpage__main">
      <div className="subpage__main__title">
        <div className="subpage__main__title">
          <h3>{props.title}</h3>
        </div>
        {
          props.sort !== 'notice' &&
          <div className='postBtnbox'
            onClick={openPostPage}
          >
            <p>글쓰기</p>
          </div>
        }
      </div>

      <div className="subpage__main__content">
        {
          props.sort === 'graderequest' && 
          <div className="warningBox">
            <p style={{marginBottom:'5px'}}>등업제도의 취지는 본 사이트를 활성화 하기 위함이며,</p>
            <p style={{marginBottom:'20px'}}>또한, 허위가입자 및 악성 광고 게시물 작성자를 근본적으로 차단하기 위해 도입된 제도입니다.</p>
            <p style={{marginBottom:'5px', fontSize:'20px', color:'#003399'}}># 등업신청 방법 (자동 등업 & 즉시 등업) </p>
            <p style={{marginBottom:'5px', fontSize:'20px', color:'#003399'}}>1. 게시물 작성하기 [추천]</p>
            <p style={{marginBottom:'20px', fontSize:'20px', color:'#333'}}>- 커뮤니티 탭 내 게시글(프로그램추천&공유, 장소후기) 1개 이상 작성</p>
            
            <p style={{marginBottom:'5px', fontSize:'20px', color:'#003399'}}>2. 등업신청 하기 </p>
            <p style={{marginBottom:'20px', fontSize:'20px', color:'#333'}}>- 커뮤니티 게시판의 게시글(프로그램추천&공유, 장소후기)에 1개 이상 댓글을 작성하고, 등업 게시판에 등업 신청</p>
            
            <p style={{color:'#FF0000'}}>* 수련회 강사로 등록되어 있는 분들께서는, 개인적으로 카톡주시면 등업해드립니다.</p>
            <p style={{color:'#FF0000'}}>* 허위로 게시물을 작성할 경우, 등업이 취소될 수 있습니다.</p>
          </div>
        }
        
        <div className="tbl_wrap">
          <div className="tbl_head01">
            <ul className='titleRow'>
              <li className="th_num">번호</li>
              <li className="th_title">제목</li>
              <li className="th_name">글쓴이</li>
              <li className="th_date">등록일</li>
              <li className="th_views">조회수</li>
            </ul>
            {
              list.length > 0 
              ?
              list.map((item:any, index:any)=>{

                return(
                  <div className='textBox' style={{}}>
                    <ul className="textRow" key={index}>
                      <li className="td_num">{item.id}</li>
                      <li className="td_title">{renderPreview(item.title)}</li>
                      <li className="td_name">{item.userNickName}</li>
                      <li className="td_date">{DateFormmating(item.date)}</li>
                      <li className="td_views"></li>
                    </ul>
                    <div className="contentRow" 
                      style={{minHeight:'50px', padding:'20px', border:'1px solid #EAEAEA', margin:'15px'}}
                    >
                      <p style={{fontSize:'16px'}}>{item.content}</p>
                    </div>
                  </div>
                )
              })
              :
              <ul className="textRow">
                <li className="td_num"></li>
                <li className="td_title"><p>작성된 글이 없습니다.</p></li>
                <li className="td_name"></li>
                <li className="td_date"></li>
                <li className="td_views"></li>
              </ul>
            }
          </div>
        </div>

        <div className='btn-row'>
          <div
            onClick={() => changePage(1)}
            className='btn'
            style={{ backgroundColor: currentPage === 1 ? "#EAEAEA" : "#2c3d54" }}
          >
            <p style={{ color: currentPage === 1 ? "#ccc" : "#fff" }}>{"<<"}</p>
          </div>
          <div
            onClick={() => changePage(currentPage - 1)}
            className='btn'
            style={{ backgroundColor: currentPage === 1 ? "#EAEAEA" : "#2c3d54" }}
          >
            <p style={{ color: currentPage === 1 ? "#ccc" : "#fff" }}>{"<"}</p>
          </div>
          {getPageNumbers().map((page) => (
            <div
              key={page}
              onClick={() => changePage(page)}
              className='btn'
              style={{ backgroundColor: currentPage === page ? "#2c3d54" : "#EAEAEA" }}
            >
              <p style={{ color: currentPage === page ? "#fff" : "#333" }}>{page}</p>
            </div>
          ))}
          <div
            onClick={() => changePage(currentPage + 1)}
            className='btn'
            style={{ backgroundColor: currentPage === totalPages ? "#EAEAEA" : "#2c3d54" }}
          >
            <p style={{ color: currentPage === totalPages ? "#ccc" : "#fff" }}>{">"}</p>
          </div>
          <div
            onClick={() => changePage(totalPages)}
            className='btn'
            style={{ backgroundColor: currentPage === totalPages ? "#EAEAEA" : "#2c3d54" }}
          >
            <p style={{ color: currentPage === totalPages ? "#ccc" : "#fff" }}>{">>"}</p>
          </div>
        </div>
        
      </div>
    
    </div>
  )
}



